import React from 'react';
import styles from './styles.module.css';
import { Player } from 'video-react';
import ZoomIcon from "../../../components/images/zoom.png";
import "video-react/dist/video-react.css";
import { LOCAL_STORAGE_KEYS } from '../../../utils/app-constants';
import { getFromLocalStorage } from '../../../utils/helper';
import { thumborUrl } from '../../../utils/url-constants';

const Image = ({ type = "image", title, url, className, baseUrl = "" }) => {

    const imgBaseUrl = getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) || "";
    const videoBaseUrl = getFromLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL) || "";
    const imageQualityParam = getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) || "";

    const handleZoomClick = url => {
        var url = "/view-image/0?url=" + encodeURI(url);
        window.open(url, "_blank", 'noopener');
    };

    return (
        <div className={styles.imgItem}>
            <div className={styles.imgContainer}>
                {type === "image" ? (
                    <>
                        <img className={styles.mainImg} src={`${imgBaseUrl}${url}${imageQualityParam}`} />
                        <div className={styles.zoom} onClick={() => handleZoomClick(url)}>
                            <img src={ZoomIcon} alt="zoom" />
                        </div>
                    </>
                ) : (
                    <Player
                        height="500px"
                        playsInline
                        src={`${baseUrl ? baseUrl : videoBaseUrl+'/'}${url}${imageQualityParam}`}
                    />
                )}
            </div>
            <div className={styles.imgTitle}>{title}</div>
        </div>
    );
};

export default Image;