import React, { useEffect, useState } from "react";
import InspectionDocuments from "../../components/inspection-documents/";
import Checkbox from "../../components/shared/checkbox/";
import InfoIcon from "../../components/shared/info-icon/component";
import BasicInfoIcon from '../../components/images/basicInfoIcon.svg';
import styles from "./inspection-review-documents.module.css";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";

const DISPLAY_ORDER = [
    "vinOBD",
    "registrationNumber",
    "registrationYear",
    "registrationMonth",
    "fitnessUpto",
    "rtoNocIssued",
    "ownerNumber",
    "isUnderHypothecation",
    "financierName",
    "loanStatus",
    "bankNocStatus",
    "bankNocExpiryDate",
    "customerName",
    "roadTaxPaid",
    "roadTaxDateValidity"
];

const parseDocumentsInOrder = (documents) => {
    const orderedItems = [];
    DISPLAY_ORDER.forEach((item) => {
        const selection = documents.find(doc => doc.key === item);
        if (selection) {
            orderedItems.push(selection);
        }
    });
    documents.forEach((item) => {
        if (!DISPLAY_ORDER.includes(item.key)) {
            orderedItems.push(item);
        }
    });
    return orderedItems;
};

const parseDocumentsByVerifiedStatus = (documents) => {
    let verifiedDocs = [];
    let genericDocs = [];
    let nonVerifiedDocs = [];
    let registrationNumberVerified = true;
    documents.forEach((item) => {
        if (item.key === "registrationNumber" || (item.key === "vinOBD" && !!item.value)) {
            genericDocs.push(item);
        } else if (item.isVerified) {
            verifiedDocs.push(item);
        } else {
            if (item.key === "registrationNumber") {
                registrationNumberVerified = false;
            }
            if (item.key === "vinOBD") {
                return;
            }
            nonVerifiedDocs.push(item);
        }
    });
    registrationNumberVerified = registrationNumberVerified && verifiedDocs.length > 0;
    return [
        verifiedDocs,
        nonVerifiedDocs,
        genericDocs,
        registrationNumberVerified
    ];
};

const InspectionReviewDocuments = ({
    inspectionDetails,
    resetInspectionDetailByKeyConnect
}) => {
    const { documentsMapper } = inspectionDetails;
    const rcCondition = (documentsMapper.find(item => item.key === "rcCondition"));
    const initialUnderHypothecation = documentsMapper.find(item => item.key === "isUnderHypothecation");
    const initialNocIssued = documentsMapper.find(item => item.key === "rtoNocIssued");
    const roadTaxPaidValue = documentsMapper.find(item => item.key === "roadTaxPaid");

    const [rcAvailability, setRcAvailability] = useState(null);
    useEffect(() => {
        if (initialUnderHypothecation && initialUnderHypothecation.value === "Yes") {
            const bankNocStatusIndex = documentsMapper.findIndex(item => item.key === "bankNocStatus");
            const financierNameIndex = documentsMapper.findIndex(item => item.key === "financierName");
            const bankNocExpiryIndex = documentsMapper.findIndex(item => item.key === "bankNocExpiryDate");
            const loanStatusIndex = documentsMapper.findIndex(item => item.key === "loanStatus");
            if (documentsMapper[bankNocStatusIndex].value === "Available") {
                if (bankNocExpiryIndex > -1) {
                    documentsMapper[bankNocExpiryIndex].isVisible = true;
                }
            }
            if(initialUnderHypothecation?.newValue === "No"){
                documentsMapper[financierNameIndex].newValue = "";
                documentsMapper[financierNameIndex].newId = "";
                documentsMapper[loanStatusIndex].newValue = "";
                documentsMapper[loanStatusIndex].newId = "";
            }
            else{
                documentsMapper[financierNameIndex].isVisible = true;
                documentsMapper[loanStatusIndex].isVisible = true;
            }

            //bankNocStatus and bankNocExpiryDate hidden
            documentsMapper[bankNocStatusIndex].isVisible = false;
            documentsMapper[bankNocExpiryIndex].isVisible = false;

            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, documentsMapper);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialUnderHypothecation]);

    useEffect(() => {
        const titles = {
            rtoNocAvailable:"RTO NOC Available",
            rtoNocIssuedFor:"RTO NOC Issued For",
            rtoNmcAvailable:"RTO NMC Available",
            rtoNocIssuedDate:"RTO NOC Issued Date"
        };
        const initialNocIssuedData = [...initialNocIssued?.dependentFields];
        initialNocIssuedData.map(item => {
            const itemIndex = documentsMapper.findIndex(element => element.key === item);
            documentsMapper[itemIndex].text = titles[item];
            if(initialNocIssued.value === "Yes" || initialNocIssued.newValue === "Yes" ){
                documentsMapper[itemIndex].isVisible= true;
            }
            if(initialNocIssued.newValue === "No"){
                documentsMapper[itemIndex].isVisible= false;
                documentsMapper[itemIndex].newId = "";
                documentsMapper[itemIndex].newValue = "";
            }
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, documentsMapper);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialNocIssued]);
    useEffect(() => {
        const initialValue = documentsMapper.find(item => item.key === "rcAvailability");
        if (JSON.stringify(initialValue) !== JSON.stringify(rcAvailability)) {
            setRcAvailability(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(documentsMapper)]);

    useEffect(() => {
        let mapper = getInitital();
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (documentsMapper.length > 0 && rcCondition) {
            let mapper = [...documentsMapper];
            const currentValue = rcCondition.newId.length > 0 ? rcCondition.newId : rcCondition.id;
            let dependentFields = [];
            const mismatchIndex = mapper.findIndex(item => item.key === "misMatchInRc");
            const chassisNoOnRcIndex = mapper.findIndex(item => item.key === "chassisNoOnRc");
            if (rcCondition.newId && rcCondition.newId.find(item => item.match(/damaged/i))) {
                dependentFields.push("chassisNoOnRc");
                mapper[chassisNoOnRcIndex].isVisible = true;
                if (rcCondition.newId.length > 0 && rcCondition.checked) {
                    mapper[chassisNoOnRcIndex].checked = true;
                    mapper[chassisNoOnRcIndex].isDisabled = true;
                }
            }
            else {
                mapper[chassisNoOnRcIndex].isVisible = false;
                mapper[chassisNoOnRcIndex].checked = false;
                mapper[chassisNoOnRcIndex].newValue = "";
                mapper[chassisNoOnRcIndex].newId = [];
            }
            // if (rcCondition.newId && rcCondition.newId.find(item => item.match(/mismatch/i))) {
            //     dependentFields.push("misMatchInRc");
            //     mapper[mismatchIndex].isVisible = true;
            //     if (rcCondition.newId.length > 0 && rcCondition.checked) {
            //         mapper[mismatchIndex].checked = true;
            //         mapper[mismatchIndex].isDisabled = true;
            //     }
            // }
            // else {
            //     mapper[mismatchIndex].isVisible = false;
            //     mapper[mismatchIndex].checked = false;
            //     mapper[mismatchIndex].newValue = "";
            //     mapper[mismatchIndex].newId = [];
            // }

            if (rcCondition.newId.length === 0 || !rcCondition.checked) {
                mapper = getInitital();
            }

            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rcCondition)]);

    useEffect(() => {
        let dependentFields = [];
        if (documentsMapper.length > 0 && documentsMapper) {
            if (rcAvailability) {
                const currentValue = rcAvailability.newValue ? rcAvailability.newValue : rcAvailability.value;
                if (currentValue === "Lost") {
                    dependentFields = [];
                }
                //updating
                let mapper = [...documentsMapper];
                const rcAtDelivery = documentsMapper.findIndex(item => item.key === "rcAtDelivery");
                const rcCondition = documentsMapper.findIndex(item => item.key === "rcCondition");
                const misMatchInRc = documentsMapper.findIndex(item => item.key === "misMatchInRc");

                mapper[rcAtDelivery].newValue = "";
                mapper[rcAtDelivery].newId = "";
                mapper[rcCondition].newValue = "";
                mapper[rcCondition].newId = "";
                mapper[misMatchInRc].newValue = "";
                mapper[misMatchInRc].newId = "";
                mapper = mapper.map(map => {
                    let element = { ...map };
                    if (dependentFields.find(field => field === element.key)) {
                        element.isVisible = false;
                    }
                    // if ()
                    return element;
                });
                resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rcAvailability]);

    const getInitital = () => {
        const currentValue = rcCondition.id;
        let mapper = [...documentsMapper];
        const chassisNoOnRcIndex = mapper.findIndex(item => item.key === "chassisNoOnRc");
        const mismatchIndex = mapper.findIndex(item => item.key === "misMatchInRc");
        if (currentValue.find(item => item.match(/damaged/i))) {
            mapper[chassisNoOnRcIndex].isVisible = true;
            mapper[chassisNoOnRcIndex].isDisabled = false;
            mapper[chassisNoOnRcIndex].checked = false;
            mapper[chassisNoOnRcIndex].newValue = "";
            mapper[chassisNoOnRcIndex].newId = [];

        }

        if (currentValue.find(item => item.match(/mismatch/i))) {
            mapper[mismatchIndex].isVisible = true;
            mapper[mismatchIndex].isDisabled = false;
            mapper[mismatchIndex].checked = false;
            mapper[mismatchIndex].newValue = "";
            mapper[mismatchIndex].newId = [];
        }
        return mapper;
    };

    const renderDocs = (docs) => {
        let elements = [];
        if (docs.length) {
            docs.forEach((item) => {
                if (item.text && item.isVisible) {
                    elements.push(
                        <InspectionDocuments
                            document={item}
                            onItemCheckCallback={(key) => {
                                if (key === "registrationNumber") {
                                    setIsVerifiedTabClicked(true);
                                }
                            }}
                        />
                    );
                }
            });
        }
        return elements;
    };

    const rearrangedDocuments = parseDocumentsInOrder(documentsMapper);
    const [
        verifiedDocuments,
        nonVerifiedDocuments,
        genericDocs,
        showCollapsedUI] = parseDocumentsByVerifiedStatus(rearrangedDocuments);
    const [isVerifiedTabClicked, setIsVerifiedTabClicked] = useState(false);
    const [isNonVerifiedTabClicked, setIsNonVerifiedTabClicked] = useState(true);
    return (
        <div className={styles.carouselContent}>
            <div className={styles.text}>Please cross-check with the images, click  <div className={styles.checkbox}><Checkbox /></div> if anything is wrong.</div>
            <p className={styles.text}>Look out for <span className={styles.info}><InfoIcon /></span> symbol for Vaahan mismatch and <img src={BasicInfoIcon} style={{ margin: "0 4px" }} /> symbol for more info.</p>
            <div className={styles.rcTextWrapper}>
                {renderDocs(genericDocs)}
                {
                    showCollapsedUI &&
                    <div className={`${styles.heading} ${isVerifiedTabClicked ? styles.active : ''}`} onClick={() => setIsVerifiedTabClicked(!isVerifiedTabClicked)}>
                        <p className={styles.headingtext}>Show Verified</p>
                    </div>
                }
                {
                    isVerifiedTabClicked &&
                    renderDocs(verifiedDocuments)
                }
                {
                    showCollapsedUI &&
                    <div className={`${styles.heading} ${isNonVerifiedTabClicked ? styles.active : ''}`} onClick={() => setIsNonVerifiedTabClicked(!isNonVerifiedTabClicked)}>
                        <p className={styles.headingtext} onClick={setIsNonVerifiedTabClicked}>Show Non Verified</p>
                    </div>
                }
                {
                    (isNonVerifiedTabClicked || !showCollapsedUI) &&
                    renderDocs(nonVerifiedDocuments)
                }
                {/* {
                    rearrangedDocuments.length > 0 && rearrangedDocuments.map(document => {
                        if (document.text && document.isVisible) {
                            return <InspectionDocuments
                                document={document}
                            />;
                        }
                    })
                } */}
            </div>

        </div>
    );
};

export default InspectionReviewDocuments;
