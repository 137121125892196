import { carDetailRejectedFieldsWithSubPartNodeArr, editableFielKeys, KEY_WITH_IDS, NUMBERS } from "../app-constants";

export const getCarDetailImageRatingfieldObject = (editedArray) => {
    let imageRatingArray = [];

    if (!!editedArray && editedArray.length > 0) {
        for (let i = 0; i < editedArray.length; i++) {
            let fieldObjectVal = {
                "section": "carDetails",
                "type": "rcImages",
                "hashKey": "inspectionData_carDetails_rcImages_RC0",
                "rating": "Yes"
            };
            imageRatingArray.push(fieldObjectVal);
        }
    }
    return imageRatingArray;
};
export const getInsuranceImagesdObject = (rejectedJsonObj, editedArray) => {
    let insuranceImagesArray = [];

    if (!!editedArray && editedArray.length > 0) {
        for (let i = 0; i < editedArray.length; i++) {
            let fieldObjectVal = {
                "hashKey": editedArray[i].hashKey,
                "title": editedArray[i].title,
                "url": editedArray[i].url,
                "message": "Rejected by qc.",
                "isValidate": 2
            };
            insuranceImagesArray.push(fieldObjectVal);
        }
    }
    if (!!insuranceImagesArray && insuranceImagesArray.length > 0) {
        rejectedJsonObj.insuranceImages = insuranceImagesArray;
    }
};
let excludedForValues = ["make", "model", "year", "variant", "registrationCity", "mismatchInRc", "rcCondition"];
const getCarDetailRejectedObj = (edititedObjectArr, rejectedJsonObj) => {
    if (!!edititedObjectArr && edititedObjectArr.length > 0) {
        for (let i = 0; i < edititedObjectArr.length; i++) {
            if (edititedObjectArr[i].newValue && edititedObjectArr[i].newId) {
                let arrayList = carDetailRejectedFieldsWithSubPartNodeArr;
                let editableExistKeyIndex = editableFielKeys.indexOf(edititedObjectArr[i].key);
                let withSubPartKeyIndex = arrayList.indexOf(edititedObjectArr[i].key);
                if (withSubPartKeyIndex < 0) {
                    let fieldObjectVal = {};
                    if (!!edititedObjectArr[i].id) {
                        let value = "";
                        if (excludedForValues.find(item => item == edititedObjectArr[i].key)) {
                            value = edititedObjectArr[i].value;
                        }
                        else {
                            value = edititedObjectArr[i].id;
                        }
                        fieldObjectVal = {
                            "title": edititedObjectArr[i].text,
                            "value": value,
                            "message": "Rejected by qc.",
                            "isValidate": editableExistKeyIndex >= 0 ? 5 : 2
                        };
                        if (edititedObjectArr[i].key === "variant") {
                            fieldObjectVal.transportation_category = edititedObjectArr[i].transportation_category;
                            fieldObjectVal.variantDisplayName = edititedObjectArr[i].variant_display_name_initial;
                            fieldObjectVal.variantParentId = edititedObjectArr[i].variantParentId;
                            fieldObjectVal.transmissionType = edititedObjectArr[i].transmissionType;
                        }

                        if (KEY_WITH_IDS.find(item => item == edititedObjectArr[i].key)) {
                            fieldObjectVal.id = !!edititedObjectArr[i].id ? edititedObjectArr[i].id : "";
                        }
                    } else {
                        fieldObjectVal = {
                            "title": edititedObjectArr[i].text,
                            "value": edititedObjectArr[i].value,
                            "message": "Rejected by qc.",
                            "isValidate": editableExistKeyIndex >= 0 ? 5 : 2
                        };
                    }
                    let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
                    getDocumentMapperFieldObj(edititedObjectArr[i].key, rejectedJsonObj, jsonfieldObjectVal);
                    // rejectedJsonObj[edititedObjectArr[i].key] = jsonfieldObjectVal;
                }
            }
        }
    }
};
const getCarDetailOtherDetailRejectedObj = (edititedObjectArr, rejectedJsonObj) => {
    if (!!edititedObjectArr && edititedObjectArr.length > 0) {
        for (let i = 0; i < edititedObjectArr.length; i++) {
            // if (edititedObjectArr[i].newValue && edititedObjectArr[i].newId) {
            let arrayList = carDetailRejectedFieldsWithSubPartNodeArr;
            let editableExistKeyIndex = editableFielKeys.indexOf(edititedObjectArr[i].key);
            let withSubPartKeyIndex = arrayList.indexOf(edititedObjectArr[i].key);
            if (withSubPartKeyIndex < 0) {
                let fieldObjectVal = {};
                if (!!edititedObjectArr[i].id) {
                    let region = {
                        regionId: edititedObjectArr[i].regionId ? edititedObjectArr[i].regionId : "",
                        regionName: edititedObjectArr[i].regionName ? edititedObjectArr[i].regionName : ""
                    };

                    fieldObjectVal = {
                        "title": edititedObjectArr[i].text,
                        "value": edititedObjectArr[i].value,
                        "message": "Rejected by qc.",
                        "isValidate": editableExistKeyIndex >= 0 ? 5 : 2
                    };
                    if (KEY_WITH_IDS.find(item => item == edititedObjectArr[i].key)) {
                        fieldObjectVal.id = !!edititedObjectArr[i].id ? edititedObjectArr[i].id : "";
                    }
                    if (edititedObjectArr[i].key === "storeName") {
                        fieldObjectVal.regionId = region.regionId ? region.regionId : "";
                        fieldObjectVal.regionName = region.regionName ? region.regionName : "";
                    }

                } else {
                    fieldObjectVal = {
                        "title": edititedObjectArr[i].text,
                        "value": edititedObjectArr[i].value,
                        "message": "Rejected by qc.",
                        "isValidate": editableExistKeyIndex >= 0 ? 5 : 2
                    };
                }
                let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
                getDocumentMapperFieldObj(edititedObjectArr[i].key, rejectedJsonObj, jsonfieldObjectVal);
                // rejectedJsonObj[edititedObjectArr[i].key] = jsonfieldObjectVal;
            }
            // }
        }
    }
};
export const getCarDetailRejectedCommentObj = (editedArray, rejectedJsonObj, qcEdit) => {
    let rejected = {};
    let commentsArray = [];
    let isNodeAdded = false;
    let nodeAdded = false;

    let isRejectedComment = editedArray.find(x => x.isRejected || x.isAdded);
    if(isRejectedComment) {
        nodeAdded = true;
    }

    // let mismatchRjrejected = editedArray.filter(x => x.isImportant === true);
    // let mismatchQCrejected = editedArray.filter(x => x.checked === true);
    // if ((!!mismatchQCrejected && mismatchQCrejected.length > 0)) {
    //     if (!!mismatchRjrejected && mismatchRjrejected.length > 0) {
    //         if (JSON.stringify(mismatchQCrejected) !== JSON.stringify(mismatchRjrejected)) {
    //             nodeAdded = true;
    //         }
    //     }
    //     else {
    //         nodeAdded = true;
    //     }
    // }

    if (nodeAdded) {
        let cjRejected = editedArray.filter(x => x.checked === true);
        if (!qcEdit) {
            cjRejected = editedArray.filter(x => x.isImportant === true);
        }
        // let qcRejected = editedArray.filter(x => x.isImportant === false && x.checked === true);
        if (!!cjRejected && cjRejected.length > 0) {
            isNodeAdded = true;
            for (let i = 0; i < cjRejected.length; i++) {
                let fieldObjectVal = {
                    "comment": cjRejected[i].comment,
                    "id": cjRejected[i].id,
                    "rank": !!cjRejected[i].rank ? cjRejected[i].rank : 0,
                    "type": !!cjRejected[i].type ? cjRejected[i].type : "Comments"
                };
                commentsArray.push(fieldObjectVal);
            }
        }
    }
    rejectedJsonObj.comments = {};
    rejectedJsonObj.comments.title = "comments";
    rejectedJsonObj.comments.value = commentsArray;
    if (!qcEdit) {
        rejectedJsonObj.comments.message = "Rejected by qc.";
        rejectedJsonObj.comments.isValidate = 5;
    }
    if (!isRejectedComment) {
        delete rejectedJsonObj.comments;
    }

};
const getCarDetailQcEditableObj = (edititedObjectArr, rejectedJsonObj) => {

    if (!!edititedObjectArr && edititedObjectArr.length > 0) {
        for (let i = 0; i < edititedObjectArr.length; i++) {
            if (edititedObjectArr[i].newValue && edititedObjectArr[i].newId) {
                let editableExistKeyIndex = editableFielKeys.indexOf(edititedObjectArr[i].key);
                let withSubPartKeyIndex = carDetailRejectedFieldsWithSubPartNodeArr.indexOf(edititedObjectArr[i].key);
                if (editableExistKeyIndex >= 0 && withSubPartKeyIndex < 0) {
                    let value = "";

                    if (excludedForValues.find(item => item == edititedObjectArr[i].key)) {
                        value = edititedObjectArr[i].newValue;
                    }
                    else {
                        value = edititedObjectArr[i].newId;
                    }

                    let fieldObjectVal = {
                        // "id": !!edititedObjectArr[i].newId ? edititedObjectArr[i].newId : "",
                        "title": edititedObjectArr[i].text,
                        "value": value
                    };
                    if (edititedObjectArr[i].key === "variant") {
                        fieldObjectVal.transportation_category = edititedObjectArr[i].transportation_category;
                        fieldObjectVal.variantDisplayName = edititedObjectArr[i].variant_display_name;
                        fieldObjectVal.variantParentId = edititedObjectArr[i].variantParentId;
                        fieldObjectVal.transmissionType = edititedObjectArr[i].transmissionType;
                    }
                    if (KEY_WITH_IDS.find(item => item == edititedObjectArr[i].key)) {
                        fieldObjectVal.id = !!edititedObjectArr[i].newId ? edititedObjectArr[i].newId : "";
                    }
                    let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
                    getDocumentMapperFieldObj(edititedObjectArr[i].key, rejectedJsonObj, jsonfieldObjectVal);
                    // rejectedJsonObj[edititedObjectArr[i].key] = jsonfieldObjectVal;
                }
            }
        }
    }
};
export const getRejectedImagesdObject = (rejectedJsonObj, editedArray, nodeType) => {
    let rejected = {};
    let imagesArray = [];
    if (!!editedArray && editedArray.length > 0) {
        for (let i = 0; i < editedArray.length; i++) {
            let fieldObjectVal = {
                "hashKey": editedArray[i].hashKey,
                "title": editedArray[i].title,
                "url": editedArray[i].url,
                "message": "Rejected by qc.",
                "isValidate": 2
            };
            imagesArray.push(fieldObjectVal);
        }

        if (nodeType === "rcImage") {
            rejectedJsonObj.rcImages = imagesArray;
        }
        else if (nodeType === "chassisNumberImage") {
            rejectedJsonObj.chassisNumberImage = imagesArray;
        }
        else if (nodeType === "additionalImage") {
            rejectedJsonObj.additionalImages = imagesArray;
        }
    }

};

export const getCarDetailPostQcEditablefieldObject = (editedArray, editInsuranceDocs, insuranceImages, editOdoMeterDocs, editMismatchMapperDocs, editOtherDetailsMapperDocs, editmakeModelVariantDocs, editrcImageDocs, editCommentsmapper, editDuplicatekeyMapper, editedCarDetailArrayAll, allInsurance) => {
    let rejected = {};
    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));

    getCarDetailQcEditableObj(editedArray, rejectedJsonObj);
    // getCarDetailQcEditableObj(editMismatchMapperDocs, rejectedJsonObj); ////not required for qc editable field
    getCarDetailQcEditableObj(editmakeModelVariantDocs, rejectedJsonObj);
    getCarDetailQcEditableObj(editOtherDetailsMapperDocs, rejectedJsonObj);

    getCarDetailRejectedCommentObj(editCommentsmapper, rejectedJsonObj, true);

    //create rejct node with subParts node - //registrationState, rcAvailability
    getRegistrationStateRejectedObject(rejectedJsonObj, editedArray, true, editedCarDetailArrayAll);

    // // as this is not the part of qceditable field so commenting it 19/02
    // getRcAvailabilityRejectedObject(rejectedJsonObj, editedArray, true, editedCarDetailArrayAll);
    // // end as this is not the part of qceditable field so commenting it 19/02

    //end create rejct node with subParts node - //registrationState, rcAvailability
    getInsurancePostObject(rejectedJsonObj, editInsuranceDocs, editMismatchMapperDocs, true, allInsurance);
    // getInsuranceImagesdObject(rejectedJsonObj, insuranceImages, true);
    // getOdometerObject(rejectedJsonObj, editOdoMeterDocs, true);

    let roadTaxPaid = editedCarDetailArrayAll.filter(x => x.checked === true
        && (x.key === "roadTaxPaid" || x.key === "roadTaxDateValidity"));
    if (!!roadTaxPaid && roadTaxPaid.length > 0) {
        getRoadTaxRejected(rejectedJsonObj, editedCarDetailArrayAll, true);
    }
    let isUnderHypothecation = editedCarDetailArrayAll.filter(x => x.checked === true &&
        (x.key === "financierName" || x.key === "loanStatus" || x.key === "isUnderHypothecation"));
    if (!!isUnderHypothecation && isUnderHypothecation.length > 0) {
        getUnderHypothecationRejected(rejectedJsonObj, editedCarDetailArrayAll, true);
    }
    let rtoNoc = editedCarDetailArrayAll.filter(x => x.checked === true &&
        (x.key === "rtoNocIssued" || x.key === "rtoNmcAvailable" || x.key === "rtoNocAvailable" || x.key === "rtoNocIssuedFor" || x.key === "rtoNocIssuedDate"));
    if (!!rtoNoc && rtoNoc.length > 0) {
        getRtoNOCIssuedRejected(rejectedJsonObj, editedCarDetailArrayAll, true);
    }
    let isRcAvailability =  editedCarDetailArrayAll.filter(x => x.checked === true &&
        (x.key === "rcAvailability" || x.key === "rcAtDelivery" || x.key === "rcCondition" || x.key === "misMatchInRc" || x.key === "chassisNoOnRc"));
    if(!!isRcAvailability && isRcAvailability.length > 0){
        getRcAvailabilityRejectedObject(rejectedJsonObj, editedArray, true, editedCarDetailArrayAll);
     }
    return rejectedJsonObj;
};

export const getCarDetailDuplicateRejectedObj = (editedArray, rejectedJsonObj) => {
    for (let i = 0; i < editedArray.length; i++) {
        let fieldObjectVal = {
            "title": editedArray[i].title,
            "value": "Yes",
            "message": "Rejected by qc.",
            "isValidate": 2
        };
        let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
        // getExteriorTyreMapperFieldObj(editedArray[i].title, rejectedJsonObj, jsonfieldObjectVal);
        if (editedArray[i].title === "Duplicate key") {
            rejectedJsonObj.duplicateKey = jsonfieldObjectVal;
            rejectedJsonObj.duplicateKey.additionalInfo = GetAdditionalInfoObject(editedArray[i]);
        }
    }
    return rejectedJsonObj;
};
export const GetAdditionalInfoObject = (addInfo) => {
    // rejectedJsonObj.additionalInfo = {};
    let newAdditionalDataforPost = [];
    let additionalInfoObject;

    let additionalInfoInnerObject = {};
    let imageObject = {
        "hashKey": addInfo.hashKey,
        "title": addInfo.title,
        "url": addInfo.url,
        "pointerCount": 0,
        "imageUploadTime": ""
    };
    additionalInfoInnerObject.image = imageObject;
    additionalInfoInnerObject.image.serverTime = addInfo.serverTime;
    additionalInfoInnerObject.image.imageUploadTime = addInfo.imageUploadTime;
    additionalInfoInnerObject.hashKey = addInfo.hashKey;
    newAdditionalDataforPost.push(additionalInfoInnerObject);
    return newAdditionalDataforPost;

};
export const getCarDetailRejectedfieldObject = (editedArray, editInsuranceDocs, insuranceImages, editOdoMeterDocs, editMismatchMapperDocs, editOtherDetailsMapperDocs, editmakeModelVariantDocs, editrcImageDocs, editCommentsmapper, editDuplicatekeyMapper, editedCarDetailArrayAll, allInsurance) => {
    let rejected = {};
    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));
    getCarDetailRejectedObj(editedArray, rejectedJsonObj);
    getCarDetailRejectedObj(editmakeModelVariantDocs, rejectedJsonObj);
    getCarDetailOtherDetailRejectedObj(editOtherDetailsMapperDocs, rejectedJsonObj);

    getCarDetailDuplicateRejectedObj(editDuplicatekeyMapper, rejectedJsonObj);
    getCarDetailRejectedCommentObj(editCommentsmapper, rejectedJsonObj, false);

    //rc Image
    // let onlyrcImages = editrcImageDocs.filter(x => x.hashKey !== "inspectionData_cardetails_chassisNumberImage");
    let onlyrcImages = editrcImageDocs.filter(x => x.imageType === "rc");
    if (!!onlyrcImages && onlyrcImages.length > 0) {
        getRejectedImagesdObject(rejectedJsonObj, onlyrcImages, "rcImage");
    }
    //chasis no image
    // let onlychasisImages = editrcImageDocs.filter(x => x.hashKey === "inspectionData_cardetails_chassisNumberImage");
    let onlychasisImages = editrcImageDocs.filter(x => x.imageType === "chasis");;
    if (!!onlychasisImages && onlychasisImages.length > 0) {
        getRejectedImagesdObject(rejectedJsonObj, onlychasisImages, "chassisNumberImage");
    }
    //Car Details Additional Images
    let onlyadditionalImages = editrcImageDocs.filter(x => x.imageType === "addnl");;
    if (!!onlyadditionalImages && onlyadditionalImages.length > 0) {
        getRejectedImagesdObject(rejectedJsonObj, onlyadditionalImages, "additionalImage");
    }

    //create rejct node with subParts node - //registrationState, rcAvailability
    getRegistrationStateRejectedObject(rejectedJsonObj, editedArray, false, editedCarDetailArrayAll);
    getRcAvailabilityRejectedObject(rejectedJsonObj, editedArray, false, editedCarDetailArrayAll);
    //end create rejct node with subParts node - //registrationState, rcAvailability

    getInsurancePostObject(rejectedJsonObj, editInsuranceDocs, editMismatchMapperDocs, false, allInsurance);
    getInsuranceImagesdObject(rejectedJsonObj, insuranceImages, false);
    // getOdometerObject(rejectedJsonObj, editOdoMeterDocs, false);

    let rtoNoc = editedCarDetailArrayAll.filter(x => x.checked === true &&
        (x.key === "rtoNocIssued" || x.key === "rtoNmcAvailable" || x.key === "rtoNocAvailable" || x.key === "rtoNocIssuedFor" || x.key === "rtoNocIssuedDate"));
    if (!!rtoNoc && rtoNoc.length > 0) {
        getRtoNOCIssuedRejected(rejectedJsonObj, editedCarDetailArrayAll, false);
    }

    let roadTaxPaid = editedCarDetailArrayAll.filter(x => x.checked === true
        && (x.key === "roadTaxPaid" || x.key === "roadTaxDateValidity"));
    if (!!roadTaxPaid && roadTaxPaid.length > 0) {
        getRoadTaxRejected(rejectedJsonObj, editedCarDetailArrayAll, false);
    }

    let isUnderHypothecation = editedCarDetailArrayAll.filter(x => x.checked === true &&
        (x.key === "isUnderHypothecation" || x.key === "bankNocStatus" || x.key === "bankNocExpiryDate" || x.key === "financierName" || x.key === "loanStatus"));
    if (!!isUnderHypothecation && isUnderHypothecation.length > 0) {
        try {
            getUnderHypothecationRejected(rejectedJsonObj, editedCarDetailArrayAll, false);
        }
        catch (error) {
            console.log("Error in getUnderHypothecationRejected method");
        }
    }

    return rejectedJsonObj;
};
export const getRoadTaxRejected = (rejectedJsonObj, editedArray, qcEdit) => {
    let rtoRoadTax = {};
    //for rtoNocIssued
    let rtoRoadTaxType = editedArray.filter(x => x.key === "roadTaxPaid");
    if (!!rtoRoadTaxType && rtoRoadTaxType.length > 0) {
        rtoRoadTax = {
            "title": rtoRoadTaxType[0].text,
            "value": qcEdit ? !!rtoRoadTaxType[0].newValue ? rtoRoadTaxType[0].newValue : rtoRoadTaxType[0].value : rtoRoadTaxType[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete rtoRoadTax.message;
            delete rtoRoadTax.isValidate;
        }
    }
    let JsonObj = JSON.parse(JSON.stringify(rtoRoadTax));
    rejectedJsonObj.roadTaxPaid = JsonObj;

    //roadTaxDateValidity
    let roadTaxPaid = editedArray.filter(x => x.checked === true && x.key === "roadTaxDateValidity");
    if (!!roadTaxPaid && roadTaxPaid.length > 0) {
        rejectedJsonObj.roadTaxPaid.subParts = {};
        let rc = {
            "title": roadTaxPaid[0].text,
            "value": qcEdit ? roadTaxPaid[0].newValue : roadTaxPaid[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete rc.message;
            delete rc.isValidate;
        }
        rejectedJsonObj.roadTaxPaid.subParts.roadTaxDateValidity = rc;
    }
    //end roadTaxDateValidity
};

export const getUnderHypothecationRejected = (rejectedJsonObj, editedArray, qcEdit) => {
    let rtoNocIssued = {};
    //for isUnderHypothecation
    let rtoNocType = editedArray.filter(x => x.key === "isUnderHypothecation");
    if (!!rtoNocType && rtoNocType.length > 0) {
        if (rtoNocType[0].checked) {
            rtoNocIssued = {
                "title": rtoNocType[0].text,
                "value": qcEdit ? (!!rtoNocType[0].newValue ? rtoNocType[0].newValue : rtoNocType[0].value) : rtoNocType[0].value,
                "message": qcEdit ? "" : "Rejected by qc.",
                "isValidate": qcEdit ? "" : NUMBERS.FIVE
            };
        } else {
            rtoNocIssued = {
                "title": rtoNocType[0].text,
                "value": qcEdit ? (!!rtoNocType[0].newValue ? rtoNocType[0].newValue : rtoNocType[0].value) : rtoNocType[0].value
            };
        }
        if (qcEdit) {
            delete rtoNocIssued.message;
            delete rtoNocIssued.isValidate;
        }
    }
    let JsonObj = JSON.parse(JSON.stringify(rtoNocIssued));
    rejectedJsonObj.isUnderHypothecation = JsonObj;

    //financierName
    // let rcfinancierName = editedArray.filter(x => x.key === "financierName" && x.checked === true);
    let rcfinancierName = editedArray.filter(x => x.key === "financierName");
    if (!!rcfinancierName && rcfinancierName.length > 0) {
        if (rcfinancierName[0].isVisible) {
            rejectedJsonObj.isUnderHypothecation.subParts = {};
            let nocClearanceTime = 0;
            if(qcEdit && !!rcfinancierName[0]?.newId ){
                nocClearanceTime = rcfinancierName[0]?.newId;
            }
            else if(!qcEdit && !!rcfinancierName[0]?.id){
                nocClearanceTime = rcfinancierName[0]?.id
            }
            let rc = {
                "title": rcfinancierName[0].text,
                "value": qcEdit ? !!rcfinancierName[0].newValue ? rcfinancierName[0].newValue : rcfinancierName[0].value : rcfinancierName[0].value,
                "message": qcEdit ? "" : "Rejected by qc.",
                "isValidate": qcEdit ? "" : 5,
                "nocClearanceTime" : nocClearanceTime
            };
            if (qcEdit || !rcfinancierName[0].checked) {
                delete rc.message;
                delete rc.isValidate;
            }
            rejectedJsonObj.isUnderHypothecation.subParts.financierName = rc;
        }
    }
    //end financierName

    //loanStatus
    let loanStatus = editedArray.filter(x => x.key === "loanStatus");
    if (!!loanStatus && loanStatus.length > 0) {
        if (loanStatus[0].isVisible) {
            if (rejectedJsonObj.isUnderHypothecation.hasOwnProperty('subParts')) { }
            else {
                rejectedJsonObj.isUnderHypothecation.subParts = {};
            }
            let rc = {
                "title": loanStatus[0].text,
                "value": qcEdit ? (!!loanStatus[0].newValue ? loanStatus[0].newValue : loanStatus[0].value) : loanStatus[0].value,
                "message": qcEdit ? "" : "Rejected by qc.",
                "isValidate": qcEdit ? "" : 5
            };
            if (qcEdit || !loanStatus[0].checked) {
                delete rc.message;
                delete rc.isValidate;
            }
            rejectedJsonObj.isUnderHypothecation.subParts.loanStatus = rc;
        }
    }
    //end loanStatus

    // if (!qcEdit) {
    //bankNocStatus
    let rcCndExpr = editedArray.filter(x => x.key === "bankNocStatus");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        if (rcCndExpr[0].isVisible) {
            if (rejectedJsonObj.isUnderHypothecation.hasOwnProperty('subParts')) { }
            else {
                rejectedJsonObj.isUnderHypothecation.subParts = {};
            }
            let rc = {
                "title": rcCndExpr[0].text,
                "value": qcEdit ? (!!rcCndExpr[0].newValue ? rcCndExpr[0].newValue : rcCndExpr[0].value) : rcCndExpr[0].value,
                "message": qcEdit ? "" : "Rejected by qc.",
                "isValidate": qcEdit ? "" : 2
            };
            if (qcEdit || !rcCndExpr[0].checked) {
                delete rc.message;
                delete rc.isValidate;
            }
            rejectedJsonObj.isUnderHypothecation.subParts.bankNocStatus = rc;
        }
    }
    //end bankNocStatus

    //bankNocExpiryDate
    rcCndExpr = editedArray.filter(x => x.key === "bankNocExpiryDate");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        if (rcCndExpr[0].isVisible) {
            if (rejectedJsonObj.isUnderHypothecation.hasOwnProperty('subParts')) { }
            else {
                rejectedJsonObj.isUnderHypothecation.subParts = {};
                rejectedJsonObj.isUnderHypothecation.subParts.bankNocStatus = {};
            }
            rejectedJsonObj.isUnderHypothecation.subParts.bankNocStatus.subParts = {};
            let rc = {
                "title": rcCndExpr[0].text,
                "value": qcEdit ? (!!rcCndExpr[0].newValue ? rcCndExpr[0].newValue : rcCndExpr[0].value) : rcCndExpr[0].value,
                "message": qcEdit ? "" : "Rejected by qc.",
                "isValidate": qcEdit ? "" : 2
            };
            if (qcEdit || !rcCndExpr[0].checked) {
                delete rc.message;
                delete rc.isValidate;
            }
            rejectedJsonObj.isUnderHypothecation.subParts.bankNocStatus.subParts.bankNocExpiryDate = rc;
        }
    }
    // }
    //end bankNocExpiryDate
};

export const getRtoNOCIssuedRejected = (rejectedJsonObj, editedArray, qcEdit) => {
    let rtoNocIssued = {};
    //for rtoNocIssued
    let rtoNocType = editedArray.filter(x => x.key === "rtoNocIssued");
    if (!!rtoNocType && rtoNocType.length > 0) {
        rtoNocIssued = {
            "title": rtoNocType[0].text,
            "value": qcEdit ? !!rtoNocType[0]?.newValue ?  rtoNocType[0].newValue : rtoNocType[0]?.value  : rtoNocType[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE
        };
        if (qcEdit || !rtoNocType[0]?.newValue) {
            delete rtoNocIssued.message;
            delete rtoNocIssued.isValidate;
        }
    }
    let JsonObj = JSON.parse(JSON.stringify(rtoNocIssued));
    rejectedJsonObj.rtoNocIssued = JsonObj;

    rejectedJsonObj.rtoNocIssued.subParts = {};

    //rtoNmcAvailable
    let rcCndExpr = editedArray.filter(x => x.key === "rtoNmcAvailable");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        let rc = {
            "title": rcCndExpr[0].text,
            "value": qcEdit ? !!rcCndExpr[0]?.newValue ? rcCndExpr[0]?.newValue : rcCndExpr[0].value: rcCndExpr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE,
            "additionalInfo" : rcCndExpr[0]?.additionalInfo || {}
        };
        if (qcEdit || !rcCndExpr[0]?.newValue) {
            delete rc.message;
            delete rc.isValidate;
        }
        if(!qcEdit || !Object.keys(rc.additionalInfo)?.length){
            delete rc.additionalInfo;
        }
        rejectedJsonObj.rtoNocIssued.subParts.rtoNmcAvailable = rc;
    }
    //end rtoNmcAvailable

    //rtoNocAvailable
    rcCndExpr = editedArray.filter(x => x.key === "rtoNocAvailable");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        let rc = {
            "title": rcCndExpr[0].text,
            "value": qcEdit ?  !!rcCndExpr[0]?.newValue ?  rcCndExpr[0]?.newValue : rcCndExpr[0]?.value : rcCndExpr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE,
            "additionalInfo" : rcCndExpr[0]?.additionalInfo
        };
        if (qcEdit  || !rcCndExpr[0]?.newValue) {
            delete rc.message;
            delete rc.isValidate;
        }
        if(!qcEdit){
            delete rc.additionalInfo;
        }
        rejectedJsonObj.rtoNocIssued.subParts.rtoNocAvailable = rc;
        // rejectedJsonObj.rtoNocIssued.subParts.rtoNocAvailable.subParts = {};
    }

    //rtoNocIssuedFor
    rcCndExpr = editedArray.filter(x => x.key === "rtoNocIssuedFor");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        let rc = {
            "title": rcCndExpr[0].text,
            "value": qcEdit ? !!rcCndExpr[0]?.newValue ? rcCndExpr[0]?.newValue : rcCndExpr[0]?.value: rcCndExpr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE
        };
        if (qcEdit  || !rcCndExpr[0]?.newValue) {
            delete rc.message;
            delete rc.isValidate;
        }
        // rejectedJsonObj.rtoNocIssued.subParts.rtoNocAvailable.subParts.rtoNocIssuedFor = rc;
        rejectedJsonObj.rtoNocIssued.subParts.rtoNocIssuedFor = rc;
    }
    //end rtoNocIssuedFor
    //rtoNocIssuedDate
    rcCndExpr = editedArray.filter(x => x.key === "rtoNocIssuedDate");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        let rc = {
            "title": rcCndExpr[0].text,
            "value": qcEdit ? !!rcCndExpr[0].newValue ? rcCndExpr[0]?.newValue : rcCndExpr[0].value : rcCndExpr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE
        };
        if (qcEdit  || !rcCndExpr[0]?.newValue) {
            delete rc.message;
            delete rc.isValidate;
        }
        // rejectedJsonObj.rtoNocIssued.subParts.rtoNocAvailable.subParts.rtoNocValidity = rc;
        rejectedJsonObj.rtoNocIssued.subParts.rtoNocIssuedDate = rc;
    }
    //end rtoNocIssuedDate
    //end rtoNocAvailable

    if(!Object.keys(rejectedJsonObj?.rtoNocIssued?.subParts)?.length || rtoNocType[0]?.newValue === "No"){
        delete rejectedJsonObj?.rtoNocIssued?.subParts;
    }
};
export const getRoadTaxIssued = (rejectedJsonObj, editedArray) => {

};

export const getRcAvailabilityRejectedObject = (rejectedJsonObj, editInsuranceDocs, qcEdit, allCarData) => {
    let rcAvailabilityType = {};
    let isRcConditionSubPartAdded = false;
    let getrcAvailFromAllCar = false;
    let isSubPartsAdded = false;
    //Created rcAvailability Type Node
    let rcAvlType = editInsuranceDocs.filter(x => x.key === "rcAvailability");
    if (!!rcAvlType && rcAvlType.length === 0) {
        rcAvlType = allCarData.filter(x => x.key === "rcAvailability");
        getrcAvailFromAllCar = true;
    }
    if (!!rcAvlType && rcAvlType.length > 0) {
        rcAvailabilityType = {
            "title": rcAvlType[0].text,
            "value": qcEdit ? (!!rcAvlType[0].newValue ? rcAvlType[0].newValue : rcAvlType[0].value): rcAvlType[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE
        };
        if (qcEdit || getrcAvailFromAllCar) {
            delete rcAvailabilityType.message;
            delete rcAvailabilityType.isValidate;
        }
    }
    let rcAvailabilityTypeJsonObj = JSON.parse(JSON.stringify(rcAvailabilityType));
    rejectedJsonObj.rcAvailability = rcAvailabilityTypeJsonObj;

    // End Created rcAvailability Type Node
    if(qcEdit ? rcAvlType[0].newValue === "Lost" : rcAvlType[0].value === "Lost"){
        return;
    }
    //rcAvailability sub parts
    rejectedJsonObj.rcAvailability.subParts = {};
    rejectedJsonObj.rcAvailability.subParts.rcCondition = {};
    //rcCondition
    let rcCndExpr = editInsuranceDocs.filter(x => x.key === "rcCondition");
    if (!!rcCndExpr && rcCndExpr.length > 0) {
        if (!!rcCndExpr[0].newValue && rcCndExpr[0].newValue !== rcCndExpr[0].value) {
            isSubPartsAdded = true;
        }
        let rc = {
            "title": rcCndExpr[0].text,
            "value": qcEdit ? rcCndExpr[0].newValue : rcCndExpr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete rc.message;
            delete rc.isValidate;
        }
        rejectedJsonObj.rcAvailability.subParts.rcCondition = rc;
    } else {
        let filterInsTypeFromAllInsurance = allCarData.filter(x => x.key === "rcCondition");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            rejectedJsonObj.rcAvailability.subParts.rcCondition = {};
            let node = {
                "title": filterInsTypeFromAllInsurance[0].text,
                "value": filterInsTypeFromAllInsurance[0].value
            };
            rejectedJsonObj.rcAvailability.subParts.rcCondition = node;
        }
    }
    //end rcCondition

    rejectedJsonObj.rcAvailability.subParts.rcCondition.subParts = {};

    //chassisNoOnRc
    let rcchasisNo = editInsuranceDocs.filter(x => x.key === "chassisNoOnRc");
    if (!!rcchasisNo && rcchasisNo.length > 0) {
        rejectedJsonObj.rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC = {};
        if (!!rcchasisNo[0].newValue && rcchasisNo[0].newValue !== rcchasisNo[0].value) {
            isSubPartsAdded = true;
            isRcConditionSubPartAdded = true;
        } 
        else if (rcchasisNo[0].isVisible) {
            isRcConditionSubPartAdded = true;
        }
        let rc = {
            "title": rcchasisNo[0].text,
            "value": qcEdit ? rcchasisNo[0].newValue : rcchasisNo[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete rc.message;
            delete rc.isValidate;
        }
        rejectedJsonObj.rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC = rc;
    }
    else {
        let filterInsTypeFromAllInsurance = allCarData.filter(x => x.key === "chassisNoOnRc");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            if (filterInsTypeFromAllInsurance[0].isVisible) {
                rejectedJsonObj.rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC = {};
                let node = {
                    "title": filterInsTypeFromAllInsurance[0].text,
                    "value": filterInsTypeFromAllInsurance[0].value
                };
                rejectedJsonObj.rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC = node;
                isRcConditionSubPartAdded = true;
            }
        }
    }
    //end chassisNoOnRc

    //rcAtDelivery
    let rcAtDelivery = editInsuranceDocs.filter(x => x.key === "rcAtDelivery");
    if (!!rcAtDelivery && rcAtDelivery.length > 0) {
        rejectedJsonObj.rcAvailability.subParts.rcAtDelivery = {};
        if (!!rcAtDelivery[0].newValue && rcAtDelivery[0].newValue !== rcAtDelivery[0].value) {
            isSubPartsAdded = true;
        }
        let rc = {
            "title": rcAtDelivery[0].text,
            "value": qcEdit ? rcAtDelivery[0].newValue : rcAtDelivery[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : NUMBERS.FIVE
        };
        if (qcEdit) {
            delete rc.message;
            delete rc.isValidate;
        }
        rejectedJsonObj.rcAvailability.subParts.rcAtDelivery = rc;
    }
    else {
        let filterInsTypeFromAllInsurance = allCarData.filter(x => x.key === "rcAtDelivery");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            if (filterInsTypeFromAllInsurance[0].isVisible) {
                rejectedJsonObj.rcAvailability.subParts.rcAtDelivery = {};
                let node = {
                    "title": filterInsTypeFromAllInsurance[0].text,
                    "value": filterInsTypeFromAllInsurance[0].value
                };
                rejectedJsonObj.rcAvailability.subParts.rcAtDelivery = node;
            }
        }
    }
    //end rcAtDelivery

    //misMatchInRc

    let misMatchInRc = editInsuranceDocs.filter(x => x.key === "misMatchInRc");
    if (!!misMatchInRc && misMatchInRc.length > 0) {
        rejectedJsonObj.rcAvailability.subParts.mismatchInRC = {};
        if (!!misMatchInRc[0].newValue && misMatchInRc[0].newValue !== misMatchInRc[0].value) {
            isSubPartsAdded = true;
        }
        let rc = {
            "title": misMatchInRc[0].text,
            "value": qcEdit ? misMatchInRc[0].newValue : misMatchInRc[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete rc.message;
            delete rc.isValidate;
        }
        rejectedJsonObj.rcAvailability.subParts.mismatchInRC = rc;
    }
    else {
        let filterInsTypeFromAllInsurance = allCarData.filter(x => x.key === "misMatchInRc");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            if (filterInsTypeFromAllInsurance[0].isVisible) {
                rejectedJsonObj.rcAvailability.subParts.mismatchInRC = {};
                let node = {
                    "title": filterInsTypeFromAllInsurance[0].text,
                    "value": filterInsTypeFromAllInsurance[0].value
                };
                rejectedJsonObj.rcAvailability.subParts.mismatchInRC = node;
            }
        }
    }
    if (!isRcConditionSubPartAdded) {
        delete rejectedJsonObj.rcAvailability.subParts.rcCondition.subParts;
    }
    if (!rcAvlType[0]?.newValue && !isSubPartsAdded) {
        delete rejectedJsonObj.rcAvailability.subParts;
    }
    if ((qcEdit || getrcAvailFromAllCar) && !isSubPartsAdded && !rcAvlType[0]?.newValue ) {
        delete rejectedJsonObj.rcAvailability;
    }
    
    //end misMatchInRc
};
export const getRegistrationStateRejectedObject = (rejectedJsonObj, editInsuranceDocs, qcEdit, allCarData) => {
    let registrationStateType = {};
    let isAnyChange = false;
    let getregStateFromAllCar = false;
    //Created registrationState Type Node
    let regState = editInsuranceDocs.filter(x => x.key === "registrationState");
    if (!!regState && regState.length === 0) {
        regState = allCarData.filter(x => x.key === "registrationState");
        getregStateFromAllCar = true;
    }

    if (!!regState && regState.length > 0) {
        if (!!regState[0].newValue && regState[0].newValue !== regState[0].value) {
            isAnyChange = true;
        }
        registrationStateType = {
            "id": qcEdit ? regState[0].newId : regState[0].id,
            "title": regState[0].text,
            "value": qcEdit ? regState[0].newValue : regState[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit || getregStateFromAllCar) {
            delete registrationStateType.message;
            delete registrationStateType.isValidate;
        }
        if (getregStateFromAllCar) {
            // delete registrationStateType.id;
            if (!!registrationStateType && registrationStateType.value === "") {
                registrationStateType.value = regState[0].value;
            }
            if (!!registrationStateType && registrationStateType.id === "") {
                registrationStateType.id = regState[0].id;
            }
        }
    }
    let registrationStateTypeJsonObj = JSON.parse(JSON.stringify(registrationStateType));
    rejectedJsonObj.registrationState = registrationStateTypeJsonObj;
    // End Created registrationState Type Node

    //registrationState sub parts
    rejectedJsonObj.registrationState.subParts = {};
    //rto
    rejectedJsonObj.registrationState.subParts.rto = {};
    let regStaterto = editInsuranceDocs.filter(x => x.key === "rto");
    if (!!regStaterto && regStaterto.length > 0) {
        if (!!regStaterto[0].newValue && regStaterto[0].newValue !== regStaterto[0].value) {
            isAnyChange = true;
        }
        let rto = {
            "id": qcEdit ? regStaterto[0].newId : regStaterto[0].id,
            "rtoCode": qcEdit ? regStaterto[0].newRtoCode : regStaterto[0].rtoCode,
            "title": regStaterto[0].text,
            "value": qcEdit ? regStaterto[0].newValue : regStaterto[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete rto.message;
            delete rto.isValidate;
        }
        rejectedJsonObj.registrationState.subParts.rto = rto;
    }
    if (!isAnyChange) {
        delete rejectedJsonObj.registrationState;
    }

    //end rto
};
export const getOdometerObject = (rejectedJsonObj, editOdoMeterDocs, qcEdit) => {
    let odoArr = editOdoMeterDocs.filter(x => x.key === "Odometer");
    if (!!odoArr && odoArr.length > 0) {
        let odoObject = {
            "title": odoArr[0].text,
            "value": qcEdit ? odoArr[0].newValue : odoArr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 2
        };
        if (qcEdit) {
            delete odoObject.message;
            delete odoObject.isValidate;
        }
        rejectedJsonObj.odometerReading = odoObject;
    }
};
export const getInsurancePostObject = (rejectedJsonObj, editInsuranceDocs, editMismatchMapperDocs, qcEdit, allInsuranceData) => {
    let insuranceType = {};
    let isAnyChange = false;
    let insuranceTypeSubPartAdded = false;
    let getinsureanceTypeAllCar = false;
    //Created Insurance Type Node
    let insType = editInsuranceDocs.filter(x => x.key === "insuranceType");
    if (!!insType && insType.length === 0) {
        insType = allInsuranceData.filter(x => x.key === "insuranceType");
        getinsureanceTypeAllCar = true;
    }
    if (!!insType && insType.length > 0) {
        if (!!insType[0].newValue && insType[0].newValue !== insType[0].value) {
            isAnyChange = true;
        }
        insuranceType = {
            "title": insType[0].text,
            "value": qcEdit ? insType[0].newValue : insType[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit || getinsureanceTypeAllCar) {
            delete insuranceType.message;
            delete insuranceType.isValidate;
        }
        if (getinsureanceTypeAllCar) {
            if (!!insuranceType && insuranceType.value === "") {
                insuranceType.value = insType[0].value;
            }
        }
    }
    let insuranceTypeJsonObj = JSON.parse(JSON.stringify(insuranceType));
    rejectedJsonObj.insuranceType = insuranceTypeJsonObj;
    // End Created Insurance Type Node

    //Insureance sub parts
    rejectedJsonObj.insuranceType.subParts = {};
    //insurance expiry
    let insExpr = editInsuranceDocs.filter(x => x.key === "insuranceExpiry");
    if (!!insExpr && insExpr.length > 0) {
        if (!!insExpr[0].newValue && insExpr[0].newValue !== insExpr[0].value) {
            isAnyChange = true;
            insuranceTypeSubPartAdded = true;
        }
        if (!!insExpr[0].isVisible) {
            isAnyChange = true;
            insuranceTypeSubPartAdded = true;
        }
        let insuranceExpiry = {
            "title": insExpr[0].text,
            "value": qcEdit ? insExpr[0].newValue : insExpr[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete insuranceExpiry.message;
            delete insuranceExpiry.isValidate;
        }
        rejectedJsonObj.insuranceType.subParts.insuranceExpiry = insuranceExpiry;
    }
    else {
        let filterInsTypeFromAllInsurance = allInsuranceData.filter(x => x.key === "insuranceExpiry");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            let node = {
                "title": filterInsTypeFromAllInsurance[0].text,
                "value": filterInsTypeFromAllInsurance[0].value
            };
            rejectedJsonObj.insuranceType.subParts.insuranceExpiry = node;
        }
    }
    //end insurance expiry
    //no claim bonus
    rejectedJsonObj.insuranceType.subParts.noClaimBonus = {};
    let noClm = editInsuranceDocs.filter(x => x.key === "noClaimBonus");
    if (!!noClm && noClm.length > 0) {
        rejectedJsonObj.insuranceType.subParts.noClaimBonus = {};
        if (!!noClm[0].newValue && noClm[0].newValue !== noClm[0].value) {
            isAnyChange = true;
            insuranceTypeSubPartAdded = true;
        }
        if (!!noClm[0].isVisible) {
            isAnyChange = true;
            insuranceTypeSubPartAdded = true;
        }
        let noclmBonus = {
            "title": noClm[0].text,
            "value": qcEdit ? noClm[0].newValue : noClm[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete noclmBonus.message;
            delete noclmBonus.isValidate;
        }
        rejectedJsonObj.insuranceType.subParts.noClaimBonus = noclmBonus;
    }
    else {
        let filterInsTypeFromAllInsurance = allInsuranceData.filter(x => x.key === "noClaimBonus");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            let node = {
                "title": filterInsTypeFromAllInsurance[0].text,
                "value": filterInsTypeFromAllInsurance[0].value
            };
            rejectedJsonObj.insuranceType.subParts.noClaimBonus = node;
        }
    }
    //end no claim bonus

    //no claim bonus percent
    rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts = {};
    let noClmPrcnt = editInsuranceDocs.filter(x => x.key === "noClaimBonuspercent");
    if (!!noClmPrcnt && noClmPrcnt.length > 0) {
        rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts = {};
        if (!!noClmPrcnt[0].newValue && noClmPrcnt[0].newValue !== noClmPrcnt[0].value) {
            isAnyChange = true;
            insuranceTypeSubPartAdded = true;
        }
        if (!!noClmPrcnt[0].isVisible) {
            isAnyChange = true;
            insuranceTypeSubPartAdded = true;
        }
        let noclmBonusprcnt = {
            "title": noClmPrcnt[0].text,
            "value": qcEdit ? noClmPrcnt[0].newValue : noClmPrcnt[0].value,
            "message": qcEdit ? "" : "Rejected by qc.",
            "isValidate": qcEdit ? "" : 5
        };
        if (qcEdit) {
            delete noclmBonusprcnt.message;
            delete noclmBonusprcnt.isValidate;
        }
        rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts.noClaimBonusPercentage = noclmBonusprcnt;
    } else {
        let filterInsTypeFromAllInsurance = allInsuranceData.filter(x => x.key === "noClaimBonuspercent");
        if (!!filterInsTypeFromAllInsurance && filterInsTypeFromAllInsurance.length > 0) {
            rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts = {};
            let node = {
                "title": filterInsTypeFromAllInsurance[0].text,
                "value": filterInsTypeFromAllInsurance[0].value
            };
            rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts.noClaimBonusPercentage = node;
        }
    }
    //end no claim bonus

    //mismatch in mapper get this info on 19 feb
    // if (!qcEdit && !!editMismatchMapperDocs && editMismatchMapperDocs.length > 0) {
    if (!!editMismatchMapperDocs && editMismatchMapperDocs.length > 0) {
        let nodeAdded = false;

        let mismatchRjrejected = editMismatchMapperDocs.filter(x => x.isImportant === true);
        let mismatchQCrejected = editMismatchMapperDocs.filter(x => x.checked === true);
        if ((!!mismatchQCrejected && mismatchQCrejected.length > 0)) {
            if (!!mismatchRjrejected && mismatchRjrejected.length > 0) {
                if (JSON.stringify(mismatchQCrejected) !== JSON.stringify(mismatchRjrejected)) {
                    nodeAdded = true;
                }
            }
            else {
                nodeAdded = true;
            }
        }
        if (nodeAdded) {
            insuranceTypeSubPartAdded = true;
            let stringArray = "";

            let cjRejected = editMismatchMapperDocs.filter(x => x.checked === true);
            if (!qcEdit) {
                cjRejected = editMismatchMapperDocs.filter(x => x.isImportant === true);
            }
            if (!!cjRejected && cjRejected.length > 0) {
                nodeAdded = true;
                const keyArray = cjRejected.map(item => {
                    return item.text;
                });
                stringArray = keyArray.join();
            }
            isAnyChange = true;
            rejectedJsonObj.insuranceType.subParts.mismatchInInsurance = {};
            let mismatch = {
                "title": "Mismatch In Insurance",
                "value": stringArray,
                "message": qcEdit ? "" : "Rejected by qc.",
                "isValidate": qcEdit ? "" : 5
            };
            // isAnyChange = true;
            rejectedJsonObj.insuranceType.subParts.mismatchInInsurance = mismatch;
        } else {
            let mismatch = {
                "title": "Mismatch In Insurance",
                "value": "No Mismatch",
            };
            // isAnyChange = true;
            rejectedJsonObj.insuranceType.subParts.mismatchInInsurance = mismatch;
        }

    }
    if (!insuranceTypeSubPartAdded) {
        delete rejectedJsonObj.insuranceType.subParts;
    }
    if (!isAnyChange) {
        delete rejectedJsonObj.insuranceType;
    } else {
        try {
            setInsuranceNodeBasedOnConditions(rejectedJsonObj, editInsuranceDocs, editMismatchMapperDocs, qcEdit, allInsuranceData);
        }
        catch (error) {

        }
    }
    //end mismatch in mapper get this info on 19 feb

    //End Insurance sub parts
};
export const setInsuranceNodeBasedOnConditions = (rejectedJsonObj, editInsuranceDocs, editMismatchMapperDocs, qcEdit, allInsuranceData) => {

    if (rejectedJsonObj.hasOwnProperty('insuranceType')) {
        let insType = allInsuranceData.filter(x => x.key === "insuranceType");
        if (!!insType && insType.length > 0) {
            let instVal = insType[0].value;
            let instNewVal = insType[0].newValue;
            if ((instNewVal === "Insurance Expired") || (instVal === "Insurance Expired" && instNewVal === "")) {
                delete rejectedJsonObj.insuranceType.subParts;
            }
            if ((instNewVal === "3rd Party") || (instVal === "3rd Party" && instNewVal === "")) {
                delete rejectedJsonObj.insuranceType.subParts.noClaimBonus;
            }
            if ((instNewVal === "Comprehensive") || (instVal === "Comprehensive" && instNewVal === "")) {
                let noClm = editInsuranceDocs.filter(x => x.key === "noClaimBonus");
                if (!!noClm && noClm.length > 0) {
                    let noClmVal = noClm[0].value;
                    let noClmNewVal = noClm[0].newValue;
                    if ((noClmNewVal === "No") || (noClmVal === "No" && noClmNewVal === "")) {
                        delete rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts;
                    }
                }
            }//
            if ((instNewVal === "Zero Depreciation") || (instVal === "Zero Depreciation" && instNewVal === "")) {
                let noClm = editInsuranceDocs.filter(x => x.key === "noClaimBonus");
                if (!!noClm && noClm.length > 0) {
                    let noClmVal = noClm[0].value;
                    let noClmNewVal = noClm[0].newValue;
                    if ((noClmNewVal === "No") || (noClmVal === "No" && noClmNewVal === "")) {
                        delete rejectedJsonObj.insuranceType.subParts.noClaimBonus.subParts;
                    }
                }
            }
        }
    }
};
export const getOtherCommentsJson = (otherComments = "") => {
    let obj = {
        title: "Other Comments",
        value: !otherComments.trim().length ? [] : otherComments.trim().split("\n")
    };
    return obj;
};

export const getACRejectedfieldObject = (editedArray) => {
    let rejected = {};
    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));

    if (!!editedArray && editedArray.length > 0) {
        for (let i = 0; i < editedArray.length; i++) {
            let fieldObjectVal = {
                "title": editedArray[i].text,
                "value": editedArray[i].newValue,
                "message": "Rejected by qc.",
                "isValidate": 2
            };
            let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
            getDocumentMapperFieldObj(editedArray[i].key, rejectedJsonObj, jsonfieldObjectVal);
            // rejectedJsonObj[editedArray[i].key] = jsonfieldObjectVal;
        }
        return rejectedJsonObj;
    }
};

export const getAdditionalInfoForExteriorTyre = (objAdditionalInfoData) => {
    let additionalInfoArray = [];
    let returnObj = Object.assign({}, objAdditionalInfoData);
    if (returnObj.isMaskedByQc) {
        if (!!returnObj["image"] && !!returnObj["image"]["oldUrl"]) {
            returnObj["image"]["url"] = returnObj["image"]["oldUrl"];
        }
        returnObj["maskedBy"] = "QC";
    }
    // let returnObj = objAdditionalInfoData;
    if (returnObj.hasOwnProperty('checked')) { delete returnObj.checked; }
    // if (returnObj.hasOwnProperty('key')) { delete returnObj.key; }
    if (returnObj.hasOwnProperty('section')) { delete returnObj.section; }//delete returnObj.section;
    if (returnObj.hasOwnProperty('title')) { delete returnObj.title; }//delete returnObj.title;
    if (returnObj.hasOwnProperty('value')) { delete returnObj.value; }//delete returnObj.value;
    if (returnObj.hasOwnProperty('index')) { delete returnObj.index; }//delete returnObj.index;
    additionalInfoArray.push(returnObj);
    return additionalInfoArray;
};

export const getEngineTransmissoinRejectedfieldObject = (additionalImagesforAdditionalInfo) => {
    let rejected = {};
    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));
    // this is the case of where there is no subparts nodes // we can maintain array
    for (let i = 0; i < additionalImagesforAdditionalInfo.length; i++) {
        let fieldObjectVal = {
            "title": additionalImagesforAdditionalInfo[i].title,
            "value": additionalImagesforAdditionalInfo[i].value,
            "message": "Rejected by qc.",
            "isValidate": additionalImagesforAdditionalInfo[i].isMaskedByQc ? 5 : 2,
            "additionalInfo": getAdditionalInfoForExteriorTyre(additionalImagesforAdditionalInfo[i])
        };
        let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
        rejectedJsonObj[additionalImagesforAdditionalInfo[i].key] = jsonfieldObjectVal;
    }
    return rejectedJsonObj;
};
export const getSteeringSuspensionRejectedfieldObject = (additionalImagesforAdditionalInfo) => {
    let rejected = {};
    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));

    // this is the case of where there is no subparts nodes // we can maintain array
    for (let i = 0; i < additionalImagesforAdditionalInfo.length; i++) {
        let fieldObjectVal = {
            "title": additionalImagesforAdditionalInfo[i].title,
            "value": additionalImagesforAdditionalInfo[i].value,
            "message": "Rejected by qc.",
            "isValidate": additionalImagesforAdditionalInfo[i].isMaskedByQc ? 5 : 2,
            "additionalInfo": getAdditionalInfoForExteriorTyre(additionalImagesforAdditionalInfo[i])
        };
        let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
        rejectedJsonObj[additionalImagesforAdditionalInfo[i].key] = jsonfieldObjectVal;
    }
    return rejectedJsonObj;
};
export const getDocumentMapperFieldObj = (key, rejectedJsonObj, carObject) => {

    //For Ac Detial Mapping
    if (key === "duplicateKey") {
        return rejectedJsonObj.duplicateKey = carObject;
    }
    if (key === "odometerReading") {
        return rejectedJsonObj.odometerReading = carObject;
    }
    if (key === "acCooling") {
        return rejectedJsonObj.acCooling = carObject;
    }
    if (key === "heater") {
        return rejectedJsonObj.heater = carObject;
    }
    if (key === "climateControlAc") {
        return rejectedJsonObj.climateControlAc = carObject;
    }
    //End for AC Detail Mapping

    // these are for cardetail related mapping
    if (key === "month") {
        return rejectedJsonObj.month = carObject;
    }
    if (key === "year") {
        return rejectedJsonObj.year = carObject;
    }
    if (key === "registrationMonth") {
        return rejectedJsonObj.registrationMonth = carObject;
    }
    if (key === "registrationYear") {
        return rejectedJsonObj.registrationYear = carObject;
    }
    if (key === "fitnessUpto") {
        return rejectedJsonObj.fitnessUpto = carObject;
    }
    if (key === "toBeScrapped") {
        return rejectedJsonObj.toBeScrapped = carObject;
    }
    if (key === "registrationState") {
        return rejectedJsonObj.registrationState = carObject;
    }
    if (key === "rto") {
        return rejectedJsonObj.rto = carObject;
    }
    if (key === "registrationCity") {
        return rejectedJsonObj.registrationCity = carObject;
    }
    if (key === "registrationNumber") {
        return rejectedJsonObj.registrationNumber = carObject;
    }
    if (key === "rtoNocIssued") {
        return rejectedJsonObj.rtoNocIssued = carObject;
    }
    if (key === "noOfOwners") {
        return rejectedJsonObj.noOfOwners = carObject;
    }
    if (key === "ownerNumber") {
        return rejectedJsonObj.ownerNumber = carObject;
    }
    //ownerNumber
    if (key === "cngLpgFitment") {
        return rejectedJsonObj.cngLpgFitment = carObject;
    }
    if (key === "chassisNumber") {
        return rejectedJsonObj.chassisNumber = carObject;
    }
    if (key === "chassisNumberEmbossing") {
        return rejectedJsonObj.chassisNumberEmbossing = carObject;
    }
    if (key === "rcAvailability") {
        return rejectedJsonObj.rcAvailability = carObject;
    }
    if (key === "rcCondition") {
        return rejectedJsonObj.rcCondition = carObject;
    }
    if (key === "rcAtDelivery") {
        return rejectedJsonObj.rcAtDelivery = carObject;
    }
    if (key === "chassisNoOnRc") {
        return rejectedJsonObj.chassisNoOnRc = carObject;
    }
    if (key === "misMatchInRc") {
        return rejectedJsonObj.misMatchInRc = carObject;
    }
    if (key === "isUnderHypothecation") {
        return rejectedJsonObj.isUnderHypothecation = carObject;
    }
    if (key === "customerName") {
        return rejectedJsonObj.customerName = carObject;
    }
    if (key === 'carColour') {
        return rejectedJsonObj.carColour = carObject;
    }

    // 
    if (key === "variant") {
        return rejectedJsonObj.variant = carObject;
    }
    if (key === "fuelType") {
        return rejectedJsonObj.fuelType = carObject;
    }
    if (key === "engineNumber") {
        return rejectedJsonObj.engineNumber = carObject;
    }
    if (key === "registrationNumber") {
        return rejectedJsonObj.registrationNumber = carObject;
    }
    // if (key === "customerName") {
    //     return rejectedJsonObj.customerName = carObject;
    // }
    // if (key === "chassisNo") {
    //     return rejectedJsonObj.chassisNo = carObject;
    // }
    // end these are for cardetail related mapping
    // these are for insurance  mapping
    if (key === "insuranceType") {
        return rejectedJsonObj.insuranceType = carObject;
    }
    if (key === "insuranceExpiry") {
        return rejectedJsonObj.insuranceType = carObject;
    }
    if (key === "noClaimBonus") {
        return rejectedJsonObj.insuranceType = carObject;
    }
    if (key === "noClaimBonuspercent") {
        return rejectedJsonObj.insuranceType = carObject;
    }

    //make model year variant 
    if (key === "make") {
        return rejectedJsonObj.make = carObject;
    }
    if (key === "model") {
        return rejectedJsonObj.model = carObject;
    }
    if (key === "year") {
        return rejectedJsonObj.year = carObject;
    }
    if (key === "variant") {
        return rejectedJsonObj.variant = carObject;
    }
    if (key === "fuelType") {
        return rejectedJsonObj.fuelType = carObject;
    }
    if (key === "toBeScrapped") {
        return rejectedJsonObj.toBeScrapped = carObject;
    }
    //end make model year variant
    // end these are for insurance mapping

    if (key === "inspectionAt") {
        return rejectedJsonObj.inspectionAt = carObject;
    }
    if (key === "city") {
        return rejectedJsonObj.city = carObject;
    }
    if (key === "storeName") {
        return rejectedJsonObj.storeName = carObject;
    }
    if (key === "emissionStandards") {
        return rejectedJsonObj.emissionStandards = carObject;
    }
};