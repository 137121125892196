import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import Inspection from '../../pages/inspection';
import InspectionReview from '../../pages/inspection-review';
import Login from '../../pages/login';
import Account from '../../pages/account';
import HealthPage from '../../pages/health-page';
import ReinspectionPage from '../../pages/reinspection-page';
import { getFromLocalStorage, isAuthorizedAccess } from '../../utils/helper';
import UnauthorizedAccess from '../../pages/unauthorized-access';
import InspectionVersion from '../../pages/inspection-version';
import InspectionPOC from '../../pages/inspection-poc';
import ViewImage from '../../components/view-image';
import InspectionBike from '../../pages/inspection-bike';
import InspectionReviewBike from '../../pages/inspection-review-bike';
import CarVariant from '../../pages/add-car-variant';
import GsRefurbishmentReview from '../../pages/gs-refurbishment-review';
import GsRefurbishment from '../../pages/gs-refurbishment';
import CarVariantListView from '../../pages/car-variant-list-view';
import { isUserRoleBIkeQc } from '../../utils/helper';
import BikeReport from '../../pages/bike-report';
import CustomerAudit from '../../pages/customer-audit-process';
import InspectionBikeVersion from '../../pages/inspection-bike-version';
import GSRefurbishmentVersion from '../../pages/gs-refurbishment-version';
import SellOnline from '../../pages/sell-online';
import PrivateRoute from './private-route';
import AuthenticatedRoute from './authenticated-route';
import XFactor from '../../pages/x-factor';
import admin from '../../pages/qc-admin-rv';
import Odometer from '../../pages/qc-admin-odo';
import UploadCJLeave from '../../pages/upload-cj-leave';
import { GsUnflagging, GsUnflaggingDetails } from '../../pages/gs-unflagging';
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from '../../utils/app-constants';
import VideoTutorialUpload from '../../pages/qc-admin-vedio-upload';
import { useUser } from '@descope/react-sdk';

let isBikeQc = isUserRoleBIkeQc();
const RenderRoutes = () => {
  const { pathname = '' } = useLocation();
  const history = useHistory();
  const [isUnauth, setIsUnauth] = useState(false);
  const { user } = useUser();
  // console.log({ user, path: pathname });
  useEffect(() => {
    if (!!user) {
      if (!isAuthorizedAccess(pathname)) {
        let currentRole = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
        if (pathname === '/' && currentRole === ROLE_KEYS.QC_VERSION) {
          history.push('/inspection-version');
        }
        setIsUnauth(true);
      } else {
        setIsUnauth(false);
      }
    }
  }, [user, pathname]);

  return (
    <main>
      <Switch>
        {isUnauth && <Route component={UnauthorizedAccess} />}
        <AuthenticatedRoute exact path="/login" component={Account} />
        <PrivateRoute
          exact
          path="/"
          component={!isBikeQc ? Inspection : InspectionBike}
        />
        <PrivateRoute exact path="/inspection" component={Inspection} />
        <PrivateRoute
          exact
          path="/re-inspection/:carId"
          component={ReinspectionPage}
        />
        <PrivateRoute
          exact
          path="/review/:carId"
          component={InspectionReview}
        />
        <PrivateRoute
          exact
          path="/inspection-version"
          component={InspectionVersion}
        />
        <PrivateRoute
          exact
          path="/inspection-bike-version"
          component={InspectionBikeVersion}
        />
        <PrivateRoute
          exact
          path="/inspection-bike-version/:appointment"
          component={InspectionBikeVersion}
        />
        <PrivateRoute
          exact
          path="/inspection-version/:appointment"
          component={InspectionVersion}
        />
        <PrivateRoute
          exact
          path="/inspection-bike"
          component={InspectionBike}
        />
        <PrivateRoute
          exact
          path="/bike-review/:appointmentId"
          component={InspectionReviewBike}
        />
        {/* <PrivateRoute exact path="/add-car-variant" component={CarVariant} />
                <PrivateRoute exact path="/car-variant-list" component={CarVariantListView} /> */}
        <PrivateRoute
          exact
          path="/gs-refurbishment"
          component={GsRefurbishment}
        />
        <PrivateRoute
          exact
          path="/gs-refurbishment-version"
          component={GSRefurbishmentVersion}
        />
        <PrivateRoute
          exact
          path="/gs-refurbishment-version/:appointment"
          component={GSRefurbishmentVersion}
        />
        <PrivateRoute
          exact
          path="/gs-refurbishment-review/:appointmentId"
          component={GsRefurbishmentReview}
        />
        <PrivateRoute
          exact
          path="/bike-report/:appointmentId"
          component={BikeReport}
        />
        <PrivateRoute exact path="/poc" component={InspectionPOC} />
        <PrivateRoute exact path="/view-image/:index" component={ViewImage} />
        <PrivateRoute exact path="/admin" component={XFactor} />
        <PrivateRoute exact path="/admin-rv" component={admin} />
        <PrivateRoute exact path="/admin-odo" component={Odometer} />
        <PrivateRoute exact path="/admin-vtt" component={VideoTutorialUpload} />
        <PrivateRoute exact path="/update-cj" component={UploadCJLeave} />
        <PrivateRoute exact path="/gs-unflagging" component={GsUnflagging} />
        <PrivateRoute
          exact
          path="/gs-unflagging-details/:appointmentId"
          component={GsUnflaggingDetails}
        />
        <Route path="/public/health" component={HealthPage} />
        <Route exact path="/sell-online" component={SellOnline} />
        <Route
          exact
          path="/customer-audit/:appointmentId/:expiryTime"
          component={CustomerAudit}
        />
        <Route component={UnauthorizedAccess} />
        <Route path="/unauth" component={UnauthorizedAccess} />
      </Switch>
    </main>
  );
};

export default RenderRoutes;
