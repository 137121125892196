import { useSelector } from 'react-redux';
import { ROLE_KEYS, NUMBERS } from '../../utils/app-constants';

const useRejectionTable = () => {
    const inspectionDetails = useSelector(state => state.inspectionDetails);
    const mappers = [
        ...inspectionDetails.documentsMapper,
        ...inspectionDetails.insuranceMapper,
        ...inspectionDetails.mismatchMapper,
        ...inspectionDetails.otherDetailsMapper,
        ...inspectionDetails.odometerMapper,
        ...inspectionDetails.duplicateKeyMapper,
        ...inspectionDetails.interiorOdometerMapper,
        ...inspectionDetails.makeModelVariantMapper,
        ...inspectionDetails.rcImagesMapper,
        ...inspectionDetails.exteriorTyresMapper,
        ...inspectionDetails.interiorImagesMapper,
        ...inspectionDetails.insuranceImageMapper,
        ...inspectionDetails.interiorDataMapper,
        ...inspectionDetails.externalDataMapper,
        ...inspectionDetails.airConditioningDataMapper,
        ...inspectionDetails.lhsFrontTyremapper,
        ...inspectionDetails.rhsFrontTyremapper,
        ...inspectionDetails.lhsRearTyremapper,
        ...inspectionDetails.spareTypremapper,
        ...inspectionDetails.rhsRearTyremapper,
        ...inspectionDetails.additionalImagesMapper,
        ...inspectionDetails.odometerComments,
        ...inspectionDetails.interiorElecctricalsMapper,
    ];
    const errors = {...inspectionDetails.carDetails?.errors};

    let prevParameterTitle = '';
    let data = mappers.filter(doc => [2,4,5].includes(doc.isValidate)).map((doc) => {
        if(prevParameterTitle === ( doc.text || doc.title)) {
            prevParameterTitle = doc.text || doc.title;
            return false;
        }
        prevParameterTitle = doc.text || doc.title;
        return {
            rejectionParameter: doc.text || doc.title,
            rejectionReason: [...(doc?.remarkHistory || [])].find(remark => [ROLE_KEYS.QC, ROLE_KEYS.QC_STORE_MANAGER, ROLE_KEYS.QC_ADMIN].includes(remark.role))?.remark || '',
        };
        
    }).filter(Boolean);
    let duplicateKeyReason = {};
    Object.keys(errors).filter(key => {
        const {isValidate, rejectionRemarks, title} = errors[key] || {};
        if ([2,4,5].includes(isValidate) && key === 'duplicateKey') {
            const remarks = rejectionRemarks.filter(item => [ROLE_KEYS.QC, ROLE_KEYS.QC_STORE_MANAGER, ROLE_KEYS.QC_ADMIN].includes(item.role));
            const lastItem = remarks.slice(-1).pop();
            duplicateKeyReason = {
                rejectionParameter: title,
                rejectionReason: lastItem?.remark || ''
            };
        }
    });
    if (Object.keys(duplicateKeyReason).length > NUMBERS.ZERO) {
        return [...data, duplicateKeyReason];
    } else {
        return [...data];
    }
};

export default useRejectionTable;