/* eslint-disable indent */
import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";
import styles from './styles.module.css';
import Back from "../../components/back";
import H2 from "../../components/h2";
import {Grid, TextField, Button}from "@material-ui/core";
import { showToastMessages} from "../../utils/helper";
import { InspectionService } from "../../service";
import { ToastContainer } from 'react-toastify';
const INITIATE_DATA = {
  appointmentId: '',
  oldOdometer: '',
  newOdometer: '',
};
const QcAdminOdometer = () => {
  const history = useHistory();
  const [data, setData] = useState(INITIATE_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  const handleInputChange = ({name, value}) => {
    setFormSubmitted(false);
    setData({
      ...data,
      [name]: value,
    });
  };
  const isValidForm = () => {
    if (!data.appointmentId) {
      showToastMessages('Invalid Appointment Id');
      return false;
    }
    if (!!data.oldOdometer && !data.newOdometer) {
      showToastMessages('Invalid odometer', false);
      return false;
    }
    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setFormSubmitted(true);
    if (isValidForm()) {
      const payload = {
        appointmentId: data.appointmentId,
        odometerReading: data.newOdometer
      };
      InspectionService.updateCorrectOdometer(payload).then((response)=>{
        if (response?.data?.status === 200) {
          showToastMessages('Odometer Reading updated succesfully');
          setData(INITIATE_DATA);
        }
      }).catch(err => {
        showToastMessages(err?.detail, false);
      }).finally(()=>{
        setIsSubmitting(false);
      });
    } else {
      setIsSubmitting(false);
    }
  };

  const populateOldReading = () => {
    InspectionService.getOdometerByAppointment(data?.appointmentId).then(response => {
      if (response?.data?.status === 200) {
        setData((data) => ({
          ...data,
          oldOdometer: response?.data?.detail?.odometerReading
        }))
      } else {
        showToastMessages(response?.data?.detail || "Enter correct Appointment Id", false);
      }
    }). catch(err => {
      showToastMessages(err?.detail || "Enter correct Appointment Id", false);
    })
  }
  return (
    <>
      <Header history={history} showPerformance={false} />
      <ToastContainer />
      <div className={styles.backSearchWrapper}>
        <Back history={history} versionFlag={true} />
      </div>
      <div className={styles.adminForm}>
        <H2  text="Admin - Odometer"/>
      </div>
      <form onSubmit={handleSubmit} className={styles.inputAdminForm}>
        <Grid container direction="column">
          <Grid item>
            <TextField
              className={styles.inputItem}
              id="appointmentId-input"
              name="appointmentId"
              label="Appointment Id"
              type="number"
              error={isSubmitting && !data.appointmentId}
              value={data.appointmentId}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value})}
            />
            <Button variant="outlined" type="button" disabled={!data?.appointmentId} onClick={populateOldReading}>Get Old Reading</Button>
          </Grid>
          <Grid item >
            <TextField
              className={styles.inputRegNo}
              id="oldOdometer-input"
              name="oldOdometer"
              label="Old Odometer Reading"
              type="number"
              value={data.oldOdometer}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value})}
              disabled
            />
          </Grid>
          <Grid item >
            <TextField
              className={styles.inputRegNo}
              id="newOdometer-input"
              name="newOdometer"
              label="New Odometer Reading"
              type="number"
              value={data.newOdometer}
              error={formSubmitted && !!data.oldOdometer && !data.newOdometer}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value.toUpperCase()})}
            />
          </Grid>
          <Button className={styles.btnContainer} variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default QcAdminOdometer;
